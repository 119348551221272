import { Formik, FormikProps } from 'formik';
import React, { forwardRef, useCallback, useRef } from 'react';

import {
  EntryList,
  FieldError,
  InputBirthdate,
  InputBirthdateAttr,
  Radio,
  RadioGroup,
  Text,
  TextField,
  TextFieldGroup,
} from '~/components/blocks';
import { numberOrHyphenRegexp } from '~/constants/regexp';
import { theme } from '~/styles/theme';
import { toHalfNumberWithHyphen } from '~/utils/convert';

import { Fields } from './types';
import { validationSchema } from './validation';

type Props = {
  initialValues: Fields;
  disabled: boolean;
  mobilePhone?: React.ReactNode;
  onSubmit: (values: Fields) => void;
};

export const GuestPatientForm = forwardRef<FormikProps<Fields>, Props>((props, ref) => {
  const { disabled } = props;
  const inputBirthdateRef = useRef<InputBirthdateAttr>(null);

  const handleChangeBirthdate = useCallback((date: string, formik: FormikProps<Fields>) => {
    formik.setFieldValue('birthDate', date);
  }, []);
  const handleChangePhoneNumber = useCallback(
    (formik: FormikProps<Fields>, e: React.FocusEvent<HTMLInputElement>) => {
      if (!numberOrHyphenRegexp.test(e.currentTarget.value)) return;
      formik.setFieldValue('mobilePhone', toHalfNumberWithHyphen(e.currentTarget.value || ''));
    },
    [],
  );

  const handleReset = useCallback(() => {
    if (inputBirthdateRef.current) {
      inputBirthdateRef.current.reset();
    }
  }, []);

  return (
    <Formik
      enableReinitialize
      innerRef={ref}
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
      onReset={handleReset}
    >
      {(formik) => (
        <>
          <EntryList>
            <EntryList.Head>氏名</EntryList.Head>
            <EntryList.Body>
              <TextFieldGroup>
                <TextField
                  disabled={disabled}
                  name="familyName"
                  placeholder="薬局"
                  error={!!formik.errors.familyName}
                  value={formik.values.familyName}
                  onChange={formik.handleChange}
                />
                <TextField
                  disabled={disabled}
                  name="givenName"
                  placeholder="太郎"
                  error={!!formik.errors.givenName}
                  value={formik.values.givenName}
                  onChange={formik.handleChange}
                />
              </TextFieldGroup>
              {(formik.errors.familyName || formik.errors.givenName) && (
                <FieldError error={(formik.errors.familyName || formik.errors.givenName)!} />
              )}
            </EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>氏名（カナ）</EntryList.Head>
            <EntryList.Body>
              <TextFieldGroup>
                <TextField
                  disabled={disabled}
                  name="phoneticFamilyName"
                  placeholder="ヤッキョク"
                  error={!!formik.errors.phoneticFamilyName}
                  value={formik.values.phoneticFamilyName}
                  onChange={formik.handleChange}
                />
                <TextField
                  disabled={disabled}
                  name="phoneticGivenName"
                  placeholder="タロウ"
                  error={!!formik.errors.phoneticGivenName}
                  value={formik.values.phoneticGivenName}
                  onChange={formik.handleChange}
                />
              </TextFieldGroup>
              {(formik.errors.phoneticFamilyName || formik.errors.phoneticGivenName) && (
                <FieldError
                  error={(formik.errors.phoneticFamilyName || formik.errors.phoneticGivenName)!}
                />
              )}
            </EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>生年月日</EntryList.Head>
            <EntryList.Body>
              <InputBirthdate
                error={formik.errors.birthDate}
                value={formik.values.birthDate}
                onChange={(date) => handleChangeBirthdate(date, formik)}
                ref={inputBirthdateRef}
              />
            </EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>性別</EntryList.Head>
            <EntryList.Body>
              <RadioGroup>
                <Radio
                  name="sex"
                  label="男性"
                  value="male"
                  checked={formik.values.sex === 'male'}
                  onChange={formik.handleChange}
                />
                <Radio
                  name="sex"
                  label="女性"
                  value="female"
                  checked={formik.values.sex === 'female'}
                  onChange={formik.handleChange}
                />
              </RadioGroup>
            </EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>電話番号（携帯）</EntryList.Head>
            <EntryList.Body>
              {props.mobilePhone ? (
                props.mobilePhone
              ) : (
                <>
                  <TextField
                    disabled={disabled}
                    name="mobilePhone"
                    placeholder="08012345678"
                    error={formik.errors.mobilePhone}
                    value={formik.values.mobilePhone}
                    onChange={formik.handleChange}
                    onBlur={(e) => handleChangePhoneNumber(formik, e)}
                  />
                  <Text color="pink" fontWeight="bold" marginTop={theme.space.m} size="xs">
                    ※メッセージ送信に電話番号（SMS送信）を利用するため、 正確に入力してください
                  </Text>
                </>
              )}
            </EntryList.Body>
          </EntryList>
        </>
      )}
    </Formik>
  );
});

GuestPatientForm.displayName = 'NewGuestPatientForm';
