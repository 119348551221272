import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ScrollBox } from '~/components/blocks';
import { useGetDisclosedMedicineNotesLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';
import { notificationState } from '~/state/layouts/SharedAppShell/atoms';
import { medicineNoteNotificationPanelState } from '~/state/partials/medicine_note_notification_panel/atoms';

export const useFetchDisclosedMedicineNotes = () => {
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);
  const { medicineNoteCount } = useRecoilValue(notificationState);
  const [state, setState] = useRecoilState(medicineNoteNotificationPanelState);
  const [event] = useSubscriptions('HiccupOnetimeApiCache', ['created']);

  // CLINICSアプリから調剤薬局を検索し、「開示許可」したお薬手帳
  // または、ワンタイムコードお薬手帳
  const [getDisclosedMedicineNotes, { loading, data }] = useGetDisclosedMedicineNotesLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      const pagesCount = getMe(result)?.organization.disclosedMedicineNotes.pagesCount || 0;
      const nodesCount = getMe(result)?.organization.disclosedMedicineNotes.nodesCount || 0;

      setState((_state) => ({
        ..._state,
        totalPage: pagesCount,
        totalCount: nodesCount,
      }));
      scrollRef.current?.toTop();
    },
  });

  const [isOnlyUnread, setOnlyUnread] = useState<boolean>(true);
  const handleGetOnlyUnread = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState((_state) => ({ ..._state, page: 1 }));
      setOnlyUnread(e.currentTarget.checked);
    },
    [setState],
  );

  useEffect(() => {
    getDisclosedMedicineNotes({
      variables: {
        page: state.page,
        perPage: state.perPage,
        onlyUnread: isOnlyUnread,
      },
    });
  }, [
    getDisclosedMedicineNotes,
    event,
    state.page,
    state.perPage,
    medicineNoteCount,
    isOnlyUnread,
  ]);

  return {
    loading,
    data,
    scrollRef,
    isOnlyUnread,
    handleGetOnlyUnread,
  };
};
