import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ScrollBox } from '~/components/blocks';
import { BASE_DATE } from '~/constants/organization_notification';
import { useGetAnsweredQuestionnaireSheetsLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import {
  medicationFollowupNotificationPanelState,
  notificationState,
} from '~/state/layouts/SharedAppShell/atoms';

export const useFetchAnsweredQuestionnaireSheets = () => {
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);
  const { followupCount } = useRecoilValue(notificationState);
  const [state, setState] = useRecoilState(medicationFollowupNotificationPanelState);
  const [getAnsweredSheet, { data, loading }] = useGetAnsweredQuestionnaireSheetsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: (_result) => {
      const pagesCount =
        getMe(_result)?.organization.medicationFollowupQuestionnaireSheets.pagesCount || 0;
      const nodesCount =
        getMe(_result)?.organization.medicationFollowupQuestionnaireSheets.nodesCount || 0;
      setState((_state) => ({
        ..._state,
        totalPage: pagesCount,
        totalCount: nodesCount,
      }));
      scrollRef.current?.toTop();
    },
  });

  const [isOnlyUnread, setOnlyUnread] = useState<boolean>(true);
  const handleGetOnlyUnread = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState((_state) => ({ ..._state, page: 1 }));
      setOnlyUnread(e.currentTarget.checked);
    },
    [setState],
  );

  useEffect(() => {
    getAnsweredSheet({
      variables: {
        page: state.page,
        perPage: state.perPage,
        from: BASE_DATE,
        onlyUnread: isOnlyUnread,
      },
    });
  }, [getAnsweredSheet, state.page, state.perPage, followupCount, isOnlyUnread]);

  return { data, loading, scrollRef, isOnlyUnread, handleGetOnlyUnread };
};
