import React, { useMemo } from 'react';
import { useRecoilCallback } from 'recoil';

import { ReceiveOption } from '~/components/partials/ReceiveOption';
import {
  AppointmentStatus,
  KarteAppointmentFragment,
  ReceptionPaneAppointmentFragment,
  UberDeliveryStatus,
} from '~/graphql';
import { changeDeliveryMethodState } from '~/state/partials/change_delivery_method_modal/atoms';
import { ChangeDeliveryMethodModal } from '~/state/partials/change_delivery_method_modal/types';

type Props = {
  appointment: KarteAppointmentFragment | ReceptionPaneAppointmentFragment;
  children?: React.ReactNode;
};

export const ReceiveOptionChangeableBlock = (props: Props) => {
  const { appointment, children } = props;
  const deliveryMethod = appointment.deliveryMethod;
  const telemedicine = appointment.telemedicine;
  const appointmentStatus = appointment.status;

  const onClick = useRecoilCallback(
    ({ set }) =>
      () =>
        set(changeDeliveryMethodState, (_state: ChangeDeliveryMethodModal) => ({
          ..._state,
          isOpen: true,
          appointmentId: appointment.id,
        })),
    [appointment],
  );

  const handleClick = useMemo(() => {
    if (!telemedicine) {
      return undefined;
    }

    if (
      appointmentStatus === AppointmentStatus.Finished ||
      appointmentStatus === AppointmentStatus.Cancelled ||
      appointmentStatus === AppointmentStatus.Noshow ||
      appointmentStatus === AppointmentStatus.Charged
    ) {
      return undefined;
    }

    const uberDelivery = appointment.uberDelivery;
    if (
      uberDelivery &&
      (uberDelivery.status === UberDeliveryStatus.Pickup ||
        uberDelivery.status === UberDeliveryStatus.Dropoff ||
        uberDelivery.status === UberDeliveryStatus.Delivered ||
        uberDelivery.status === UberDeliveryStatus.Canceled ||
        uberDelivery.status === UberDeliveryStatus.UberSupportCanceled)
    ) {
      return undefined;
    }

    return onClick;
  }, [appointment.uberDelivery, appointmentStatus, onClick, telemedicine]);

  return (
    <ReceiveOption deliveryMethod={deliveryMethod} onClick={handleClick}>
      {children}
    </ReceiveOption>
  );
};
