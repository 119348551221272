import { useTheme } from '@emotion/react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Alert, Flex, Grid, Icon, Text } from '~/components/blocks';
import { ExternalLink } from '~/components/blocks/ExternalLink';
import { useFeatureFlag } from '~/hooks/use-feature-flag';

type Props = {
  children: React.ReactNode;
};

// TODO: Uber無料キャンペーンが終わりFeatureFlagの制御が入らなくなったタイミングで削除する
export const UberFreeInformation = (props: Props) => {
  const displayUberFreePromotion = useFeatureFlag('Issue9813-before-promotion');
  const { children } = props;
  const theme = useTheme();
  const isTablet = useMediaQuery({ query: theme.mediaQueries.tablet });

  if (displayUberFreePromotion) {
    return (
      <Grid height="100%" gridTemplateRows="min-content 1fr">
        <Alert
          status="info"
          marginTop={theme.space.l}
          marginLeft={theme.space.xl}
          marginRight={theme.space.xl}
        >
          <Flex alignItems="center">
            <Text size={isTablet ? 'xs' : 's'}>
              2025年3月1日（土）〜
              3月31日（月）の間、当日配達料無料キャンペーンを開催！この機会に当日配達機能の利用をご検討ください（
              <ExternalLink url="https://intercom.help/pharms/ja/articles/9765203">
                キャンペーン詳細
              </ExternalLink>
              <Icon
                icon="blank"
                size={isTablet ? 's' : 'm'}
                marginLeft={theme.space.m}
                marginRight={theme.space.m}
                color="linkBlue"
              />
              ）
            </Text>
          </Flex>
        </Alert>
        {children}
      </Grid>
    );
  }

  return <>{children}</>;
};
