import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef } from 'react';
import { useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil';

import { Icon, Overlay, Popover } from '~/components/blocks';
import { NotificationPanel } from '~/components/layouts/SharedAppShell/NotificationPanel';
import { useFetchUnreadTemporaryPermissions } from '~/components/partials/MedicineNoteNotificationBadge/use-fetch-unread_temporary_permissions';
import { announcementModalState } from '~/state/layouts/announcement_modal/atoms';
import {
  medicationFollowupNotificationPanelState,
  notificationPanelState,
  notificationState,
} from '~/state/layouts/SharedAppShell/atoms';
import { entryNotificationDrawerState } from '~/state/partials/entry_notification_drawer/atoms';
import { medicineNoteNotificationPanelState } from '~/state/partials/medicine_note_notification_panel/atoms';
import { organizationNotificationDrawerState } from '~/state/partials/organization_notification_drawer/atoms';

const Root = styled('div')(({ theme }) =>
  css({
    position: 'relative',
    cursor: 'pointer',
    padding: theme.space.m,
  }),
);

const Badge = styled('div')(({ theme }) =>
  css({
    display: 'inline-block',
    position: 'absolute',
    left: `calc(${theme.space.xl} - ${theme.space.xs})`,
    top: `calc(${theme.space.m} - ${theme.space.xs})`,
    backgroundColor: theme.colors.colorPallete.pink,
    borderRadius: '50%',
    color: theme.colors.text.white,
    fontSize: theme.fontSizes.xs,
    width: `calc(${theme.space.l} + ${theme.space.xs})`,
    height: `calc(${theme.space.l} + ${theme.space.xs})`,
    lineHeight: `calc(${theme.space.l} + ${theme.space.xs})`,
    textAlign: 'center',
    fontWeight: 'bold',
  }),
);

const NotificationPopover = styled(Popover)(() =>
  css({
    left: '-16px !important', // react-popperで自動調整されてしまうため、右側を開けるように調整
  }),
);

export const NotificationBadge = () => {
  const { unreadCount } = useFetchUnreadTemporaryPermissions();
  const anckerRef = useRef<HTMLDivElement>(null);

  const [{ isOpen }, setNotificationPanelState] = useRecoilState(notificationPanelState);
  const resetOrganizationNotificationDrawerState = useResetRecoilState(
    organizationNotificationDrawerState,
  );
  const resetMedicationFollowupNotificationPanelState = useResetRecoilState(
    medicationFollowupNotificationPanelState,
  );
  const resetEntryNotificationDrawerState = useResetRecoilState(entryNotificationDrawerState);
  const resetMedicineNoteNotificationPanelState = useResetRecoilState(
    medicineNoteNotificationPanelState,
  );

  const [{ receptionCount, entryCount, followupCount, medicineNoteCount }, setNotificationState] =
    useRecoilState(notificationState);
  const count = receptionCount + entryCount + followupCount + medicineNoteCount;

  const handleClick = useRecoilCallback(({ set }) => () => {
    setNotificationPanelState((_state) => ({ ..._state, isOpen: !isOpen }));
    set(announcementModalState, (_state) => ({ ..._state, isOpen: false }));
  });

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      // 要素外をクリックした場合に閉じる
      if (e.target === e.currentTarget) {
        setNotificationPanelState((_state) => ({ ..._state, isOpen: false }));

        resetOrganizationNotificationDrawerState();
        resetMedicationFollowupNotificationPanelState();
        resetEntryNotificationDrawerState();
        resetMedicineNoteNotificationPanelState();
      }
    },
    [
      setNotificationPanelState,
      resetOrganizationNotificationDrawerState,
      resetMedicationFollowupNotificationPanelState,
      resetEntryNotificationDrawerState,
      resetMedicineNoteNotificationPanelState,
    ],
  );

  useEffect(() => {
    setNotificationState((_state) => ({ ..._state, medicineNoteCount: unreadCount }));
  }, [setNotificationState, unreadCount]);

  return (
    <div>
      <Root onClick={handleClick} ref={anckerRef}>
        {count > 0 ? <Badge>{count}</Badge> : null}
        <Icon size="xl" color="navy" icon="notification" />
      </Root>
      {anckerRef.current && (
        <Overlay transparent open={isOpen} onClick={handleClose}>
          <NotificationPopover
            inside
            open={isOpen}
            placement={'bottom'}
            anckerEl={anckerRef.current}
          >
            <NotificationPanel />
          </NotificationPopover>
        </Overlay>
      )}
    </div>
  );
};
