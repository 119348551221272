import { useRouter } from 'next/router';
import React from 'react';

import { Loader } from '~/components/blocks';
import { ClientCertVerificationStatus } from '~/graphql';

import { ClientCertError } from './ClientCertError';
import { Reentry } from './Reentry';
import { useClientCertVerification } from './use-client_cert_verification';

type Props = {
  children: React.ReactNode;
};

export const ClientCertVerification: React.FC<Props> = (props) => {
  const { pathname } = useRouter();
  const { status, redirectUrl, loaded } = useClientCertVerification(pathname);

  if (!loaded) {
    return <Loader open />;
  }

  if (
    (status === ClientCertVerificationStatus.RedirectToApp ||
      status === ClientCertVerificationStatus.RedirectToS) &&
    redirectUrl
  ) {
    return (
      <Reentry
        message="こちらのボタンからアクセスし直してください"
        link={redirectUrl}
        linkText="Pharmsに再アクセスする"
      />
    );
  }
  if (status === ClientCertVerificationStatus.Ng) {
    return <ClientCertError redirectUrl={redirectUrl} />;
  }

  return <>{props.children}</>;
};
