import React from 'react';

import { B2CloudField } from '~/components/partials/DeliveryMethodField/B2CloudField';
import { ReceiveOptionChangeableBlock } from '~/components/partials/ReceiveOptionChangeableBlock';
import { ReceptionPaneAppointmentFragment } from '~/graphql';
import { isB2Cloud } from '~/utils/appointments';

import { UberDeliveryField } from './UberDeliveryField';

type Props = {
  appointment: ReceptionPaneAppointmentFragment;
};

const Detail = ({ appointment }: Props) => {
  if (appointment.deliveryMethod === 'same_day_delivery' && appointment.uberDelivery?.id) {
    return <UberDeliveryField uberDelivery={appointment.uberDelivery} />;
  }

  if (isB2Cloud(appointment.deliveryMethod)) {
    return <B2CloudField deliveryMethod={appointment.deliveryMethod} />;
  }

  return null;
};

export const ReceiveOption = React.memo(({ appointment }: Props) => {
  return (
    <ReceiveOptionChangeableBlock appointment={appointment}>
      <Detail appointment={appointment} />
    </ReceiveOptionChangeableBlock>
  );
});

ReceiveOption.displayName = 'ReceiveOption';
