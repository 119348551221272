// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"a3dddf29342fc611f168b344e054b1bc3ea01268"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/pharmacy/assets/production-20250401092658-a3dddf29342fc611f168b344e054b1bc3ea01268";import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.sentryDsn,
  enabled: process.env.NODE_ENV !== 'test',
  release: process.env.sentryRelease,
  ignoreErrors: [],
});
