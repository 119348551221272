import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';

import { Alert, Box, EntryList, Flex, Loader, Text } from '~/components/blocks';
import { AppointmentStatusLabel, MemoField } from '~/components/partials';
import { MedicationInstructionDateTime } from '~/components/partials/MedicationInstructionDateTime';
import { MedicationInstructionMethodLabel } from '~/components/partials/MedicationInstructionMethodLabel';
import { MedixsPatientProfileOnReceptionPane } from '~/components/partials/PatientKarte/ReceptionPane/MedixsPatientProfile';
import { PatientProfileOnReceptionPane } from '~/components/partials/PatientKarte/ReceptionPane/PatientProfile';
import { ReceiveOptionChangeableBlock } from '~/components/partials/ReceiveOptionChangeableBlock';
import { AppointmentDeliveryMethod, KarteAppointmentFragment } from '~/graphql';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';
import { translateReason } from '~/utils/appointments';
import { Label } from '~/utils/label';

import { useUpdateMemo } from './use-update-memo';

type Props = {
  appointment?: KarteAppointmentFragment | null;
};

const PatientBox = styled(Box)(({ theme }) =>
  css({
    borderTop: `1px solid ${theme.colors.border.default}`,
    paddingTop: theme.space.l,
  }),
);

export const ReceptionPane = React.memo((props: Props) => {
  const theme = useTheme();
  const { enabledMedixs } = useMedixsSetting();

  const { appointment } = props;
  const patient = appointment?.patient;
  const medixsPatient = patient?.medixsPatient;

  const cancellationReason = appointment?.cancellationReason
    ? translateReason(
        appointment.cancellationReason,
        appointment.status,
        appointment.telemedicine ? undefined : !!appointment.waitingForChargeAt,
      )
    : null;

  const { isUpdating, latestMemo, update } = useUpdateMemo(appointment?.id);
  const handleChangeMemo = useCallback(
    async (memo: string) => {
      try {
        await update(memo);
      } catch {
        // 何もしない
      }
    },
    [update],
  );

  return (
    <Box position="relative" height="100%" overflow="auto" padding={theme.space.l}>
      {!appointment ? (
        <Loader open inside appearance="white" />
      ) : (
        <>
          <Flex flexDirection="column">
            <Flex justifyItems="start" marginY={theme.space.m} alignItems="center">
              <MedicationInstructionMethodLabel method="telemedicine" />
              <Box marginLeft="auto">
                <AppointmentStatusLabel
                  status={appointment.status}
                  uberDelivery={appointment.uberDelivery}
                  isSameDayDelivery={
                    appointment.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery
                  }
                />
              </Box>
            </Flex>
            {appointment.start && appointment.end && (
              <MedicationInstructionDateTime start={appointment.start} end={appointment.end} />
            )}
          </Flex>
          <ReceiveOptionChangeableBlock appointment={appointment} />
          {cancellationReason && (
            <Alert status="warning" marginY={theme.space.m} withIcon={false}>
              <Flex flexDirection="column">
                <Text size="s" fontWeight={theme.fontWeights.bold}>
                  {cancellationReason.canceledBy}
                  からキャンセル
                </Text>
                {cancellationReason.canceledBy === '薬局' && (
                  <Text size="s">{cancellationReason.detail}</Text>
                )}
              </Flex>
            </Alert>
          )}

          <PatientBox>
            {enabledMedixs && (
              <Box>
                <Flex alignItems="center">
                  <Text fontWeight="bold" marginBottom={theme.space.m}>
                    MEDIXS連携情報
                  </Text>
                </Flex>
                {medixsPatient ? (
                  <MedixsPatientProfileOnReceptionPane
                    medixsPatient={medixsPatient}
                    medixsReception={appointment.medixsReception}
                  />
                ) : (
                  <Text>未連携</Text>
                )}
              </Box>
            )}
            {patient && (
              <>
                <Text
                  fontWeight="bold"
                  marginTop={enabledMedixs ? theme.space.xl : '0px'}
                  marginBottom={theme.space.m}
                >
                  患者情報
                </Text>
                <PatientProfileOnReceptionPane patient={patient} />
              </>
            )}
            <Text fontWeight="bold" marginTop={theme.space.xl} marginBottom={theme.space.m}>
              その他
            </Text>
            <EntryList>
              <EntryList.Head>申し込み日時</EntryList.Head>
              <EntryList.Body>
                <Box>
                  <Text size="m">
                    {`${Label.YYYYMMDDja(appointment.createdAt)}
        ${Label.HHMM(appointment.createdAt)}`}
                  </Text>
                </Box>
              </EntryList.Body>
            </EntryList>
            <EntryList mt={theme.space.l}>
              <EntryList.Head>メモ</EntryList.Head>
              <EntryList.Body>
                <MemoField
                  disabled={isUpdating}
                  value={
                    latestMemo.mutationCalled ? latestMemo.memo : appointment.description || ''
                  }
                  onChange={handleChangeMemo}
                />
              </EntryList.Body>
            </EntryList>
          </PatientBox>
        </>
      )}
    </Box>
  );
});

ReceptionPane.displayName = 'ReceptionPane';
